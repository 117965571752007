import { Game } from "./types";

export const othergames: Game[] = [
    {
        name: "TrackMania Nations Forever",
        details: "Legendäres Rennspiel mit Online-Modus",
        link: "https://store.steampowered.com/app/11020/TrackMania_Nations_Forever/",
        label: "Gratis auf Steam",
    },
    {
        name: "Risiko",
        details: "Taktikspiel um Weltherrschaft",
        link: "https://store.steampowered.com/app/1128810/RISK_Global_Domination/",
        label: "Gratis auf Steam",
    },
    {
        name: "Catan Universe",
        details: "Klassiker: Siedler von Catan",
        link: "https://store.steampowered.com/app/544730/Catan_Universe/",
        label: "Gratis auf Steam",
    },
    {
        name: "Goose Goose Duck",
        details: "Social Deduction Game mit Gänsen und Enten",
        link: "https://store.steampowered.com/app/1568590/Goose_Goose_Duck/",
        label: "Gratis auf Steam",
    },
    {
        name: "Quiplash 2",
        details: "Partyspiel mit lustigen Antworten auf Fragen",
        link: "https://store.steampowered.com/app/1111940/Quiplash_2_InterLASHional/",
        label: "PvP mit Screenshare auf Steam",
    },
    {
        name: "Cluedo",
        details: "Klassiker: finde den Mörder",
        link: "https://store.steampowered.com/app/794800/ClueCluedo_Classic_Edition/",
        label: "PvP mit Screenshare auf Steam",
    },
    {
        name: "Das Spiel des Lebens 2",
        details: "Klassiker: finde den Mörder",
        link: "https://store.steampowered.com/app/1455630/The_Game_of_Life_2/",
        label: "PvP mit Screenshare auf Steam",
    },
    {
        name: "Discord Games",
        details: "Minispiele innerhalb der Discord-Plattform",
        link: "https://discord.com/",
        label: "Discord starten",
    },
];
