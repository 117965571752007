import { WhatsAppButton } from "../../Button";
import { TableContainer, Table, Tr, Td } from "../../Table/Table";
import { Dispatch, SetStateAction } from "react";

const StyledTable: React.FC<GruppenProps> = ({ setPageId }) => {
    return (
        <TableContainer>
            <Table>
                <colgroup>
                    <col span={1} />
                    <col span={1} />
                    <col span={1} style={{ minWidth: "200px" }} />
                </colgroup>

                <tbody>
                    <Tr>
                        <Td>🎲 Klassische Gesellschafts- und Brettspiele</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/J09MJyQ6dwuAyWNfL2GhZX"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>⏰ Ausdauer-Brettspiele</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/GtubKnh2tOxEvre9ruAsFv"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🛋️ Videospiele vor Ort (Nintendo Switch & Co.)</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/GzWZ5pn3twrDT0YgGnI1fn"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🖥️ Online Gaming</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/IkkBEmDdV2G1ryLWAGFLfr"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🎾 Tennis</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/DDr7pukFwi2IqbVs8v5DmH"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🏐 Sportspiele aller Art</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/HjDY3RtE6xr5a6fuYMfxtO"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🪵 Outdoor-Spiele</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/HrB6wrxKEeVAj3p17UWqWe"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>♣️ Pa-Pa-Pa-Pokerface</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/BbuqhtsvuSk21PujVnrwcs"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>💭 Quizzards & Quizzardinen</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/KPcROZeHFtUEMRQ85UwArk"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🎱 Pub games</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/KMOkxuioT1G8Qe8jMrJcRL"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🎤 Singstars & Karaoke</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/EVWEPVKpczy7qcPcusO0dt"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🎭 Impro-Theater Workshops</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/BVzIxFVyO1s43vLTiIgOPL"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🚪 Escape Rooms</Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/DP7eBlbK83XI0mlvufT0si"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                </tbody>
            </Table>
        </TableContainer>
    );
};

type GruppenProps = {
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const Gruppen: React.FC<GruppenProps> = (props) => {
    return (
        <>
            <section>
                <h1>Übersicht WhatsApp Gruppen</h1>
                <StyledTable {...props} />
                <WhatsAppButton
                    link="https://chat.whatsapp.com/IYyE1gy3mm079JqjHUQuMf"
                    label="zur WhatsApp Community"
                />
            </section>
        </>
    );
};
