import React, { Dispatch, SetStateAction } from "react";
import { styled } from "styled-components";

const StyledNav = styled.nav`
    background: #333;
    color: white;
    padding: 0.5em;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    justify-content: center;
`;

const StyledMenuItem = styled.div`
    color: white;
    margin: 0.4em;
    padding: 0.2em;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

type MenuItemProps = {
    pageId: string;
    label: string;
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

const MenuItem: React.FC<MenuItemProps> = ({ pageId, label, setPageId }) => {
    return (
        <StyledMenuItem onClick={() => setPageId(pageId)}>
            {label}
        </StyledMenuItem>
    );
};

type NavigationProps = {
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const Navigation: React.FC<NavigationProps> = ({ setPageId }) => {
    return (
        <StyledNav>
            <MenuItem setPageId={setPageId} pageId="home" label="Home" />
            <MenuItem setPageId={setPageId} pageId="gruppen" label="WhatsApp" />
            <MenuItem
                setPageId={setPageId}
                pageId="onlinegames"
                label="Online"
            />
            <MenuItem
                setPageId={setPageId}
                pageId="kalender"
                label="Kalender"
            />
        </StyledNav>
    );
};
