import React, { Dispatch, SetStateAction } from "react";
import { Button, ExternalButton, WhatsAppButton } from "../../Button";
import { COLORS } from "../../../constants";

type HomeProps = {
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const Home: React.FC<HomeProps> = ({ setPageId }) => {
    return (
        <section>
            <h1>Willkommen bei Ludopia! 🎲</h1>
            <article>
                <p>
                    Hey! Schön, dass du hier bist! Ich bin Berni, und ich freue
                    mich, dir Ludopia vorzustellen – eine Plattform für alle,
                    die das Spielen lieben!
                </p>
                <p>
                    Ob Volleyball, Theater, Brettspiele oder PC-Gaming, meine
                    Leidenschaft für Spiele hat mich dazu inspiriert, eine
                    Gemeinschaft zu gründen, die all diese Aktivitäten
                    verbindet.
                </p>
                <h3>Warum Ludopia? 🤔</h3>
                <p>
                    Die Organisation von Spieleabenden kann manchmal
                    herausfordernd sein: Wie bringe ich verschiedene
                    Freundesgruppen zusammen? Wo finde ich die perfekte Zeit und
                    Location? Jeder hat unterschiedliche Vorlieben – manche
                    mögen schnelle Spiele, andere vertiefen sich lieber in
                    epische Abenteuer.
                </p>
                <p>
                    <strong>Ludopia</strong> soll helfen, diese
                    Herausforderungen zu meistern und eine große
                    Spiele-Community zu schaffen, in der wir gemeinsam Spaß
                    haben können!
                </p>
                <p>Sei dabei und lass uns zusammen spielen! 🎉</p>
                <div>
                    <h3>Weitere Infos:</h3>
                    <Button onClick={() => setPageId("faq")} label="FAQ" />
                    <Button
                        onClick={() => setPageId("spielregeln")}
                        label="Spielregeln"
                    />
                </div>
            </article>
            <article>
                <h1>Wie komme ich zur Community?</h1>
                <WhatsAppButton
                    link="https://chat.whatsapp.com/IYyE1gy3mm079JqjHUQuMf"
                    label="zur WhatsApp Community"
                />
                <Button
                    onClick={() => setPageId("gruppen")}
                    label="Übersicht WhatsApp Gruppen"
                    backgroundColor={COLORS.whatsapp}
                />
                <ExternalButton
                    link="https://discord.gg/RkNDQmJ23t"
                    label="zum Discord Server"
                    backgroundColor={COLORS.discord}
                />
            </article>
            <article>
                <h1>Wann finden Spiele-Events statt?</h1>
                <Button
                    onClick={() => setPageId("kalender")}
                    label="zum Kalender"
                    backgroundColor="cornflowerblue"
                />
            </article>
        </section>
    );
};
