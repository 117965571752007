import React, { Dispatch, SetStateAction } from "react";
import {
    Onlinegames,
    FAQ,
    Gruppen,
    Home,
    Kalender,
    Spielregeln,
} from "./pages";
import styled from "styled-components";

const ContentContainer = styled.main`
    max-width: 60em;
    margin: 0 auto;
    padding: 1em;
`;

type ContentProps = {
    pageId: string;
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const Content: React.FC<ContentProps> = ({ pageId, setPageId }) => {
    const getPage = () => {
        switch (pageId) {
            case "home":
                return <Home setPageId={setPageId} />;
            case "faq":
                return <FAQ setPageId={setPageId} />;
            case "kalender":
                return <Kalender />;
            case "spielregeln":
                return <Spielregeln />;
            case "gruppen":
                return <Gruppen setPageId={setPageId} />;
            case "onlinegames":
                return <Onlinegames />;
        }
    };

    return <ContentContainer>{getPage()}</ContentContainer>;
};
